import Loader from '@appmaker/components/Loading';
import { useParams, Link } from '@appmaker/core/routes';
import { useState, useEffect, useCallback } from 'react';
import { getExtensionProject } from '../api/extension';
import debounce from 'lodash/debounce';
import { isAdmin } from '@appmaker/core/store/user';
const ExtensionInstallList = () => {
    const { extensionId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const admin = isAdmin();

    const fetchData = async (search = '') => {
        setIsLoading(true);
        try {
            const response = await getExtensionProject({
                handle: extensionId,
                limit,
                offset,
                where: search ? { name: { contains: search } } : {}
            });
            setData(response?.getExtensionProjectList?.data || []);
            setTotalCount(response?.getExtensionProjectList?.totalCount || 0);
        } catch (error) {
            setIsError(true);
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const debouncedSearch = useCallback(
        debounce((search) => {
            setOffset(0); // Reset to first page when searching
            fetchData(search);
        }, 500),
        [limit] // Recreate debounced function when limit changes
    );

    useEffect(() => {
        fetchData(searchTerm);
    }, [limit, offset, extensionId]);

    useEffect(() => {
        debouncedSearch(searchTerm);
        return () => debouncedSearch.cancel();
    }, [searchTerm, debouncedSearch]);
    if(!admin){
        return (
            <div className="min-h-screen bg-gray-50 px-4 py-6">
                <div className="rounded-lg bg-red-50 p-4 text-center">
                    <h1 className="text-xl font-semibold text-red-700">You are not authorized to access this page</h1>
                </div>
            </div>
        );
    }
    if (isError) {
        return (
            <div className="min-h-screen bg-gray-50 px-4 py-6">
                <div className="rounded-lg bg-red-50 p-4 text-center">
                    <h1 className="text-xl font-semibold text-red-700">An error occurred while fetching data</h1>
                    <button
                        onClick={() => fetchData()}
                        className="mt-4 rounded-md bg-red-600 px-4 py-2 text-white hover:bg-red-700"
                    >
                        Retry
                    </button>
                </div>
            </div>
        );
    }

    if (!extensionId) {
        return (
            <div className="min-h-screen bg-gray-50 px-4 py-6">
                <div className="rounded-lg bg-yellow-50 p-4 text-center">
                    <h1 className="text-xl font-semibold text-yellow-700">Extension ID is required</h1>
                </div>
            </div>
        );
    }

    const totalPages = Math.ceil(totalCount / limit);
    const currentPage = Math.floor(offset / limit) + 1;

    const handlePageChange = (newPage) => {
        setOffset((newPage - 1) * limit);
    };

    // Calculate visible page numbers
    const getVisiblePages = () => {
        const delta = 2; // Number of pages to show on each side of current page
        const range = [];
        const rangeWithDots = [];
        let l;

        for (let i = 1; i <= totalPages; i++) {
            if (i === 1 || i === totalPages || (i >= currentPage - delta && i <= currentPage + delta)) {
                range.push(i);
            }
        }

        range.forEach(i => {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        });

        return rangeWithDots;
    };

    return (
        <div className="min-h-screen bg-gray-50 px-4 py-6">
            <div className="mx-auto max-w-7xl">
                <div className="mb-6">
                    <div className="flex items-center justify-between mb-4 bg-white p-4 rounded-lg shadow">
                        <h1 className="text-2xl font-semibold text-gray-900">
                            Extension: <span className="text-indigo-600">{extensionId}</span>
                        </h1>
                    </div>

                    <div className="flex justify-end mb-4">
                        <select
                            id="limit"
                            value={limit}
                            onChange={(e) => {
                                setLimit(Number(e.target.value));
                                setOffset(0);
                            }}
                            className="w-32 rounded-md border border-gray-300 px-2 py-1 text-sm"
                        >
                            <option value={10}>10 per page</option>
                            <option value={25}>25 per page</option>
                            <option value={50}>50 per page</option>
                            <option value={100}>100 per page</option>
                        </select>
                    </div>

                    {isLoading && (
                        <div className="bg-blue-50 text-blue-700 p-2 rounded mb-4">
                            Loading content, please wait...
                        </div>
                    )}
                </div>

                <div className="mt-4 overflow-hidden rounded-lg bg-white shadow relative min-h-[400px]">
                    {isLoading && (
                        <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10">
                            <div className="flex flex-col items-center">
                                <Loader className="w-12 h-12" />
                                <span className="mt-2 text-sm text-gray-500">Loading data...</span>
                            </div>
                        </div>
                    )}

                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th colSpan="3" className="px-6 py-3">
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center">
                                                <input
                                                    type="text"
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                    className="rounded-md border border-gray-300 px-4 py-2 text-sm w-80"
                                                    placeholder="Search by project name..."
                                                />
                                                <select
                                                    value={limit}
                                                    onChange={(e) => {
                                                        setLimit(Number(e.target.value));
                                                        setOffset(0);
                                                    }}
                                                    className="ml-4 rounded-md border border-gray-300 px-2 py-2 text-sm w-32"
                                                >
                                                    <option value={10}>10 per page</option>
                                                    <option value={25}>25 per page</option>
                                                    <option value={50}>50 per page</option>
                                                    <option value={100}>100 per page</option>
                                                </select>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        Project ID
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        Name
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {data.map((item, index) => (
                                    <tr key={item.remoteProjectId} className={index % 2 === 0 ? 'bg-white hover:bg-gray-50' : 'bg-gray-50 hover:bg-gray-100'}>
                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                            {item.remoteProjectId}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                            {item.name}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-sm">
                                            <a
                                                href={`https://console.appmaker.xyz/apps/${item.remoteProjectId}/extension/${extensionId}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-indigo-600 hover:text-indigo-900 hover:underline"
                                            >
                                                View Details
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                {!isLoading && data.length === 0 && (
                                    <tr>
                                        <td colSpan="3" className="px-6 py-12 text-center text-sm text-gray-500 bg-gray-50">
                                            <div className="flex flex-col items-center">
                                                <svg className="h-12 w-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                                </svg>
                                                <span className="mt-2">No projects found</span>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Pagination */}
                {totalPages > 0 && (
                    <div className="mt-4 flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 rounded-lg shadow">
                        <div className="flex flex-1 justify-between sm:hidden">
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                Previous
                            </button>
                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                Next
                            </button>
                        </div>
                        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                            <div>
                                <p className="text-sm text-gray-700">
                                    Showing <span className="font-medium">{offset + 1}</span> to{' '}
                                    <span className="font-medium">
                                        {Math.min(offset + limit, totalCount)}
                                    </span>{' '}
                                    of <span className="font-medium">{totalCount}</span> results
                                </p>
                            </div>
                            <div>
                                <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                                    <button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                        className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
                                    >
                                        <span className="sr-only">Previous</span>
                                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                    {getVisiblePages().map((pageNum, index) => (
                                        pageNum === '...' ? (
                                            <span
                                                key={`ellipsis-${index}`}
                                                className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
                                            >
                                                ...
                                            </span>
                                        ) : (
                                            <button
                                                key={pageNum}
                                                onClick={() => handlePageChange(pageNum)}
                                                className={`relative inline-flex items-center border px-4 py-2 text-sm font-medium transition-colors duration-200 ${currentPage === pageNum
                                                    ? 'z-10 border-indigo-500 bg-indigo-50 text-indigo-600 hover:bg-indigo-100'
                                                    : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50'
                                                    }`}
                                            >
                                                {pageNum}
                                            </button>
                                        )
                                    ))}
                                    <button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                        className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
                                    >
                                        <span className="sr-only">Next</span>
                                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                </nav>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExtensionInstallList;