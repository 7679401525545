import DropDownMenu from '@appmaker/components/DropDownMenu';
import { isAdmin } from '@appmaker/core/store/user';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useHistory } from '@appmaker/core/routes';

export default function ListItem(props) {
	const history = useHistory();
	const { extension, getRouterPath } = props;
	let bgColor = 'bg-white';
	if (extension?.type === 'admin' || extension?.type === 'private') {
		bgColor = 'bg-blue-50';
	}
	if (extension?.type === 'unlisted') {
		bgColor = 'bg-red-50';
	}
	const settingsOption = [
		{
			label: 'Settings',
			onClick: () => {
				history?.push(getRouterPath('/extension/' + extension?.handle));
			},
		},
	];
	const admin = isAdmin();
	if (admin) {
		settingsOption.push({
			label: 'Project List',
			onClick: () => {
				history?.push(
					getRouterPath(
						'/admin-tools/extension-project-list/' + extension?.handle
					)
				);
			},
		});
	}
	return (
		<div
			className={clsx(
				'p-2 group rounded-xl border hover:shadow relative border-gray-300',
				bgColor
			)}
		>
			<div className="relative group flex items-center justify-between space-x-3">
				<Link
					to={getRouterPath('/extension/' + extension?.handle)}
					className="flex items-center justify-between space-x-3 flex-1"
				>
					<div className="aspect-square rounded-lg overflow-hidden bg-gray-100 h-9 w-9">
						<img
							src={
								extension?.icon ||
								'https://storage.googleapis.com/appilder_cdn/extension-default.png'
							}
							alt={extension?.imageAlt}
							className="object-contain object-center h-9 w-9"
						/>
					</div>
					<div className="flex-1">
						<h3 className="text-md font-medium group-hover:text-blue-600 capitalize">
							{extension?.name}
						</h3>
						{admin && extension?.packageVersion && (
							<p className="text-xs capitalize text-gray-500">
								Version: {extension?.packageVersion}
							</p>
						)}
					</div>
				</Link>
				{/* TODO - Extension List Dropdown */}
				<DropDownMenu options={settingsOption} />
			</div>
		</div>
	);
}