import { getProjectId } from '@appmaker/core/store/project';
import axios from 'axios';
import { DASHBOARD_GRAPHQL_URL, APPMAKER_GQL_URL } from '@appmaker/core/api/core';

const getExtension = async ({
	extensionId,
	limit,
	offset,
	orderBy,
	search,
}) => {
	const projectId = getProjectId();
	const query = `
    query ($extensionId: String!, $limit: Int, $offset: Int, $orderBy: String, $search: String) {
        extension(
          id: $extensionId
          limit: $limit
          offset: $offset
          orderBy: $orderBy
          search: $search
        ) {
          id
          tag
          projects {
            id
            name
          }
        }
      }
        `;

	const response = await axios.post(
		`${DASHBOARD_GRAPHQL_URL}/${projectId}/`,
		JSON.stringify({
			query,
			variables: {
				extensionId,
				limit,
				offset,
				orderBy,
				search,
			},
		}),
		{
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
			withCredentials: true,
		}
	);
	return response?.data?.data;
};

const getExtensionProject = async ({
	limit,
	offset,
	handle,
	where = {}
}) => {
	const projectId = getProjectId();
	const query = `
		query (
				$handle: String!
				$take: Int
				$skip: Int
				$where: ProjectWhereInputForProjectList
				) {
				getExtensionProjectList(
					handle: $handle
					take: $take
					skip: $skip
					where: $where
				) {
					totalCount
					data {
						remoteProjectId
						name
					}
				}
		}

	`
	const response = await axios.post(
		APPMAKER_GQL_URL,
		JSON.stringify({ query, variables: { handle, take: limit, skip: offset, where } }),
		{
			headers: {
				'Content-Type': 'application/json',
				'x-appmaker-project-id': projectId,
			},
			credentials: 'include',
			withCredentials: true,
		}
	);
	return response?.data?.data;
}


export {
	getExtension,
	getExtensionProject
}